import React from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'react-router-redux'
import { connect } from 'react-redux'
import App from './containers/App/App'
import asyncComponent from './helpers/AsyncFunc'

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/signin',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)
const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <div>
          <Route
            exact={true}
            path={'/'}
            component={asyncComponent(() =>
              import('./containers/Page/signin/signin')
            )}
          />
          <Route
            exact={true}
            path={'/signin'}
            component={asyncComponent(() =>
              import('./containers/Page/signin/signin')
            )}
          />
          <Route
            exact={true}
            path={'/signin-shop'}
            component={asyncComponent(() =>
              import('./containers/Page/sub-admin/src/components/login/login')
            )}
          />
          <Route
            exact={true}
            path={'/signin-shop-manager'}
            component={asyncComponent(() =>
              import(
                './containers/Page/sub-admin/src/components/login/login-shop-manager'
              )
            )}
          />
          <Route
            path={'/reset-password'}
            component={asyncComponent(() =>
              import('./containers/Page/reset_password/resetPassword')
            )}
          />
          <Route
            exact={true}
            path={'/forgot-password'}
            component={asyncComponent(() =>
              import('./containers/Page/forgot_password/forgotPassword')
            )}
          />
          <Route
            path={'/reset-password-shop'}
            component={asyncComponent(() =>
              import(
                './containers/Page/sub-admin/src/components/reset_password/resetPassword'
              )
            )}
          />
          <Route
            path={'/reset-password-shop-manager'}
            component={asyncComponent(() =>
              import(
                './containers/Page/sub-admin/src/components/reset_password/resetPasswordShopManager'
              )
            )}
          />
          <Route
            exact={true}
            path={'/forgot-password-shop'}
            component={asyncComponent(() =>
              import(
                './containers/Page/sub-admin/src/components/forgot_password/forgotPassword'
              )
            )}
          />
          <Route
            exact={true}
            path={'/forgot-password-shop-manager'}
            component={asyncComponent(() =>
              import(
                './containers/Page/sub-admin/src/components/forgot_password/forgotPasswordShop'
              )
            )}
          />
          <RestrictedRoute
            path='/admin'
            component={App}
            isLoggedIn={isLoggedIn}
          />
          <Route
            path={'/reset-password-user'}
            component={asyncComponent(() =>
              import('./containers/Page/reset_password/resetPasswordUser')
            )}
          />
          <Route
            path={'/reset-password-driver'}
            component={asyncComponent(() =>
              import('./containers/Page/reset_password/resetPasswordDriver')
            )}
          />
          <Route
            path={'/reset-password-collector'}
            component={asyncComponent(() =>
              import(
                './containers/Page/reset_password/resetPasswordCoordinator'
              )
            )}
          />
          <Route
            path={'/activate-user'}
            component={asyncComponent(() =>
              import('./containers/Page/activate_user/activate_user')
            )}
          />
          <Route
            exact={true}
            path={'/delete-instructions'}
            component={asyncComponent(() =>
              import(
                './containers/Page/delete_inscructions/delete-instructions'
              )
            )}
          />
          <Route
            exact={true}
            path={'/privacy-policy'}
            component={asyncComponent(() =>
              import('./containers/Page/privacy_policy/privacy_policy')
            )}
          />
          {/* <Route
            exact
            path={"/404"}
            path={"/"}
            component={asyncComponent(() => import("./containers/Page/404"))}
          />
          <Route
            exact
            path={"/500"}
            component={asyncComponent(() => import("./containers/Page/500"))}
          /> */}
        </div>
      </Switch>
    </ConnectedRouter>
  )
}

export default connect((state) => {
  return {
    isLoggedIn: state.Auth.get('idToken') !== null,
  }
})(PublicRoutes)
